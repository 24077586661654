var counter = function() {
    if ($('.body').hasClass('editable')) {
      var rawHtml = $('.editable').html().split('    +')[0]
      var value = $('.editable').html().split('    +')[0].replace(/\+|(<([^>]+)>)/ig," ").trim();

      if (value.length == 0) {
          $('#wordCount').html(0);
          $('#totalChars').html(0);
          $('#charCount').html(0);
          $('#charCountNoSpace').html(0);
          $('#readingTime').html("Nothing to read!");
          return;
      }

      var regex = /\s+/gi;
      var wordCount = value.trim().replace(regex, ' ').split(' ').length;
      var totalChars = value.length;
      var charCount = value.trim().length;
      var charCountNoSpace = value.replace(regex, '').length;
      var paragraphCount = (rawHtml.match(/<p/g) || []).length;
      var readingTime = (wordCount/265).toFixed(0)
      var readingTimeHtml = ""
      if (readingTime < 2){
        readingTimeHtml = "About 1 minute"
      } else {
        readingTimeHtml = "About " + readingTime.toString() + " minutes"
      }

      var wordCount = numberWithCommas(wordCount)
      var charCount = numberWithCommas(charCount);
      var totalChars = numberWithCommas(totalChars);
      var charCountNoSpace = numberWithCommas(charCountNoSpace);
      var paragraphCount = numberWithCommas(paragraphCount);


      $('#wordCount, #wordsCount').html(wordCount);
      $('#totalChars').html(totalChars);
      $('#charCount').html(charCount);
      $('#charCountNoSpace').html(charCountNoSpace);
      $('#paragraphCount').html(paragraphCount);
      $('#readingTime').html(readingTimeHtml);

      function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
  };
};

window.wordCounter = function() {
    setTimeout(() => {$('.editable').ready(counter);}, 500);
    setTimeout(() => {$('.editable').ready(counter);}, 1000);
    setTimeout(() => {$('.editable').ready(counter);}, 2000);
    setTimeout(() => {$('.editable').ready(counter);}, 5000);
    setTimeout(() => {$('.editable').ready(counter);}, 10000);
    $('.editable').change(counter);
    $('.editable').keydown(counter);
    $('.editable').keypress(counter);
    $('.editable').keyup(counter);
    $('.editable').blur(counter);
    $('.editable').focus(counter);
};
