jQuery(document).ready(function() {
  if($(".post-analytics").length > 0){
    let slug = $(".post-analytics").data(`slug`);
    $.get(`/profile/posts/${slug}/analytics.json`, function( data ) {
      let views = (!data.views) ? 0 : data.views
      let visits = (!data.visits) ? 0 : data.visits 
      $( ".post-analytics .views" ).html(`${views} Views`);
      $( ".post-analytics .visits" ).html(`${visits} Unique Visits`);
    });
  }
});
