function is_mobile_width(){
  let window_width = $(window).width()
  let mobile_width = '768' // mobile breakpoint
  if (window_width < mobile_width){return true;};
};

function is_tablet_width(){
  let window_width = $(window).width()
  let tablet_width = '992' // in width for responsive content
  if (window_width <= tablet_width){return true;};
};

// Documents Sorting
$(document).on('turbo:load', function() {

  let body = $("html, body")
  let filters = $('.filter-row')
  let button = $('.btn-collapse')
  let buttonFilters = $('.btn-filters')
  let buttonAdvFilters = $('.btn-advanced-filters')
  let documentSorting = $('#sticky-sorting-container')
  let documentBody = $('#documents-body')

  // Reset on Window Resize
  $(window).resize(function() {
    resetFilterWindow();
  }); 

  // Button Actions
  button.click(function(){
    $(this).toggleClass('closed opened');
    body.animate({ scrollTop: 0 }, "fast");
  });

  buttonFilters.click(function(){
    if (!$(this).hasClass('opened')){
      resetFilterWindow();
    };
  });

  buttonAdvFilters.click(function() {
    if ($(this).hasClass('opened')){
      documentSorting.addClass('position-relative')
      documentSorting.css({'width': '100%'});
      adjustMargins();
    }else{
      resetFilterWindow();
    };
  });

  function adjustMargins(){
    if (!is_mobile_width() && !is_tablet_width() ){
      documentBody.css({'padding-top': '30px'});
      console.log("shift body down");
    }
  }

  function resetFilterWindow(){
    filters.collapse('hide')
    buttonFilters.removeClass('opened');
    buttonFilters.addClass('closed');
    buttonAdvFilters.removeClass('opened');
    buttonAdvFilters.addClass('closed');
    documentSorting.removeClass('position-relative')
    documentSorting.css({'width' : ''})
    documentBody.css({'padding-top' : ''})
    console.log("reset filters to default css")
  };

});