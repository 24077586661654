$(document).on('click', '.dropdown-toggle', function (e) {
  console.log("prevent default");
  e.preventDefault();
});
  
/// Pin Button
$(document).on('click', '#trigger-pin.pinned-false', function () {
  console.log("set pin");
  togglePin($(this),"true");
});

$(document).on('click', '#trigger-pin.pinned-true', function () {
  console.log("remove pin");
  togglePin($(this),"false");
});

function togglePin(object,state){
  let obj = object
  let new_value = state
  let old_value = (new_value == "true") ? "false":"true";
  let base_class = "pinned-"
  let card = ".thumbnail"
  obj.addClass(base_class + new_value);
  obj.removeClass(base_class + old_value);
  obj.closest(card).removeClass(base_class + old_value).addClass(base_class + new_value);
  console.log("pinned set to " + new_value);
};

/// Trash Button

$(document).on('click','#trigger-trash', function (e) {
  console.log("toggle trash");
  let post = $(this)
  let status = post.attr('data-status')
  toggleStatus(post, status);
  e.preventDefault();
});

$(document).on('click','.menu-post-link', function (e) {
  e.preventDefault();
});

// Prevent contextual menu from closing on item click
$(document).on('hide.bs.dropdown', function (e) {
  if (e && e.clickEvent && e.clickEvent.target) {
    let target = $(e.clickEvent.target);
    if (!target.hasClass("dropdown-item")) {
      // This is useful when your drop down items container complex hierarchies.
      // JQuery parents() will find the first ancestor in the DOM hierarchy using some selector.
      target = target.parents(".dropdown-item");
    }

    if (target.length > 0 && target.hasClass("noclose")) {
      return false;
    }
  }

  return true;
});

function toggleStatus(post, status){

  let card = post.closest('.thumbnail')
  let status_container = card.find('.post-status')

  let trash_btn = card.find('.action-trash')
  let restore_btn = card.find('.action-restore')
  let delete_btn = card.find('#trigger-delete')

  if (status == 'TRASH') {
    var new_status = "DRAFT"
    console.log("set new_status to " + new_status)
    trash_btn.show()
    restore_btn.hide()
    delete_btn.hide()
  } else {
    var new_status = "TRASH"
    console.log("set new_status to " + new_status)
    trash_btn.hide()
    restore_btn.show()
    delete_btn.show()
  };

  status_container.removeClass('status-' + status.toLowerCase() )
  status_container.addClass('status-' + new_status.toLowerCase() )
  status_container.children('span').removeClass(status)
  status_container.children('span').addClass(new_status)
  status_container.children('span').html("<span class='status-icon'></span> " + new_status)

  post.attr('data-status', new_status)
  console.log("status is now " + new_status)
};