import Rails from '@rails/ujs';

$(document).on('click', '#trigger-save, #trigger-save-form', function () {
  manualSave();
});

$(document).on('turbo:before-render', function() {
  if (typeof window.autoInterval !== 'undefined') {
    editorTeardown();
  }
});

//init autosave on page load
document.addEventListener("DOMContentLoaded", function() {
  var autosave_on = document.getElementsByClassName('posts edit autosave');
  if (autosave_on.length > 0) {
    console.log("starting autosave on document load")
    startAutoSave();
    startAutoSaveTimeout();
  }
});

function setTurboInterval(intervalFunction, milliseconds) {
  window.autoInterval = setInterval(intervalFunction, milliseconds);
}

function editorTeardown(){
  clearTimeout(window.indicatePausedAutosave);
  clearTimeout(window.autoSaveTimeout);
  clearTimeout(window.countdownPaused);
  clearInterval(window.autoInterval)
  delete window.autoInterval
  delete window.autoSaveEnabled
  delete window.autoSaveTimeout
  delete window.indicatePausedAutosave
  console.log("cleared post editor timeouts")
}

function startAutoSave() {
  //first check that autosave is disabled to prevent initializing more than once. 
  let autoSaveDisabled = window.hasOwnProperty('autoSaveEnabled') && autoSaveEnabled == false || typeof autoSaveEnabled === 'undefined'
  if (autoSaveDisabled == true) {
    window.autoSaveInterval = setTurboInterval(autoSave, 5000) //submit form every 5 seconds
    window.autoSaveEnabled = true
    console.log("autosave enabled")
  }
}

function startAutoSaveTimeout() {
  if (typeof autoSaveTimeout === "undefined") {
    // expire autosave after approx 1 minute (bug causes issues if expires at same interval as form submit)
    window.autoSaveTimeout = setTimeout(expireAutoSave, 62000);
    console.log("autosave timeout enabled")
  }
}
 
function expireAutoSave() {
  stopAutoSave();
  setTimeoutAutoSavePaused();
  console.log("autosave paused due to inactivity")
  document.addEventListener("keyup", handler, false)
  document.addEventListener("paste", handler, false)

  function handler() {
    document.removeEventListener("keyup", handler)
    document.removeEventListener("paste", handler)
    resetAutoSave();
  }
}

function setTimeoutAutoSavePaused() {
  if (window.indicatePausedAutosave === undefined){
    window.countdownPaused = setTimeout(autoSavePaused, 5000)
    window.logOutTimeout = setTimeout(function() {
      window.location.href = "/documents"
      console.log("redirected back to documents due to 30 minutes of inactivity")
    }, 3600000) // redirect to /documents after 30 minutes of inactivity.
  }
}

function saving(){
  $(".save-icon").fadeOut('fast');
  $(".save-indicator").show();
  $(".save-indicator").delay('1000').fadeOut('fast');
  $(".save-icon").delay('1000').fadeIn('fast');
  setTimeout( function(){ $(".save-icon").parent().parent().removeClass('disabled'); }, 1000 );
}

function setTime(){
  let today = new Date()
  return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
}

function manualSave(){
  window.failedSave = setTimeout(manualSaveFailed, 5001);
  saving();
  const form = document.querySelector("#publishForm")
  Rails.fire(form, "submit")
  console.log("manually saved at " + setTime())
}

function autoSave(){
  window.failedSave = setTimeout(autoSaveFailed, 5001);
  const form = document.querySelector("#publishForm")
  Rails.fire(form, "submit")
  console.log("autosaved at " + setTime())
}

function removeClassByPrefix(node, prefix) {
	var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
	node.className = node.className.replace(regx, '');
	return node;
}

function clearAlerts(){
  var alerts = document.querySelectorAll('.editor-alert');
  document.body.classList.remove("sticky-alert");
  removeClassByPrefix(document.body, 'connection-')
}

function showAlert(name){
  var alert_name = name
  var alert = document.getElementById(alert_name);
  document.body.classList.add("sticky-alert");
}

function bodyStatus(name){
  var name = name
  document.body.classList.add(name)
}

function buttonStatus(add_class, data_title){
  var saveIcon = document.getElementById('trigger-save');
  var add_class = add_class
  var data_title = data_title

  removeClassByPrefix(saveIcon, 'connection-')
  saveIcon.classList.add(add_class);
  saveIcon.querySelector('#saveDesc').setAttribute("data-original-title", data_title);
}

window.stopAutoSave = function(){
  clearInterval(window.autoInterval)
  window.autoInterval = false
  window.autoSaveEnabled = false
  console.log("autosave disabled")
}

window.resetAutoSave = function() {
  clearTimeout(window.indicatePausedAutosave);
  delete window.indicatePausedAutosave
  clearTimeout(window.autoSaveTimeout);
  clearTimeout(window.countdownPaused);
  delete window.autoSaveTimeout
  startAutoSave();
  startAutoSaveTimeout();
  console.log("autosave restarted")
  buttonStatus('connection-active', 'Autosave enabled.');
}

window.autoSavePaused = function() {
  if(!document.body.classList.contains("connection-paused")){
    clearAlerts();
    bodyStatus('connection-paused');
    buttonStatus('connection-paused', 'Start typing to resume autosave.');
  }
}

window.autoSaveDisabled = function() {
  if(!document.body.classList.contains("connection-paused")){
    clearAlerts();
    bodyStatus('connection-paused');
    buttonStatus('connection-paused', 'Save');
  }
}

window.autoSaveFailed = function() {
  if(!document.body.classList.contains("connection-failed")){
    clearAlerts();
    bodyStatus('connection-failed');
    showAlert("connectionFailed");
    buttonStatus('connection-failed', 'Changes are not being saved.');
  }
}

window.autoSaveSucceeded = function() {
  if(!document.body.classList.contains("connection-active")){
    clearAlerts();
    bodyStatus('connection-active');
    buttonStatus('connection-active', 'Autosave enabled.');
  }
}

window.manualSaveFailed = function() {
  clearAlerts();
  showAlert("manualSaveFailed");
}

window.manualSaveSucceeded = function() {
  clearAlerts();
  showAlert("manualSaveSucceeded");
}