import * as InfiniteScroll from "infinite-scroll"

document.addEventListener("turbo:load", function() {
  var scrollItems = ["#grid-posts", "#form-posts", "#grid-collections", ".external-images", "#answers-list"]
  scrollItems.forEach(item => initInfiniteScroll(item));
});
  
window.initInfiniteScroll = function(domElement){
  let elem = document.querySelector(domElement);
  if (elem !== null) {
    var infScroll = new InfiniteScroll( elem, {
      // options
      path: '.pagination .next a',
      checkLastPage: true,
      append: '.item-container',
      status: '.page-load-status',
      hideNav: '.pagination-container',
      debug: true,
      history: false,
      prefill: true
    });
  }
}