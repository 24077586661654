// Editor Actions
$(document).ready(function(){

  action_container = $('.recommendations-container > .collapse')
  default_action = $('#action_post_description')
  action_btn = $('.btn-incomplete')

  default_action.removeClass('collapsed') // opened by default

  action_btn.click(function(){
    action_container.collapse('hide'); 
  });

});

$('.posts.edit').ready(function(){
  $('#trigger-fullscreen').on('click', function(e){
    toggleFullScreen(document.body)
    $('.screen-status').toggleClass("fullscreen-expand fullscreen-contract");
  });

  $('#toggle-trash').on('click', function(e){
    $('body').toggleClass("status-trash");
  });

});

// Mobile Editor
$('.posts.edit').ready(function(){
  // Button Actions

  // Actions: Text Formatting
  $('#editorActionBold').on('click', function(e){
    document.execCommand("bold");
  });
  $('#editorActionItalic').on('click', function(e){
    document.execCommand("italic");
  });
  $('#editorActionUnderline').on('click', function(e){
    document.execCommand("underline");
  });
  $('#editorActionStrikethrough').on('click', function(e){
    document.execCommand("strikethrough");
  });
  $('#editorActionHeading').on('click', function(e){
    setStandardTag('H2');
  });
  $('#editorActionBlockquote').on('click', function(e){
    setStandardTag("BLOCKQUOTE");
  });
  $('#editorActionUnorderedList').on('click', function(e){
    document.execCommand("insertUnorderedList");
  });
  $('#editorActionOrderedList').on('click', function(e){
    document.execCommand("insertOrderedList");
  });
  $('#editorActionUndo').on('click', function(e){
    document.execCommand("undo");
  });
  $('#editorActionRedo').on('click', function(e){
    document.execCommand("redo");
  });

  // Highlight parent tag of cursor position in mobile editor
  $('.editable, #publish-mobile-editor').on('click keyup', function(e){
    let parentTag = getParentElement();
    setEditorButton(parentTag);
  });

  function getParentElement() {
    let parentElement = null, sel;
    let currentSelection = window.getSelection();
    // if at least one range is selected
    if (currentSelection.rangeCount) {
      //Range.commonAncestorContainer returns the deepest Node that contains both boundary points of the Range.
      parentElement = currentSelection.getRangeAt(0).commonAncestorContainer;
      // if parent container is not an element, move up to next parent.
      if (parentElement.nodeType != 1) {
        parentElement = parentElement.parentNode;
        
        // this doesn't work to detect lists, since each li has an inner p
        // we may want to add some custom functionality for detecting UL or OL containers if paragraph with li?
        if(parentElement.parentNode){
          let grandparentElement = parentElement.parentNode
          console.log(grandparentElement.tagName)
        }
      }
      let parentTag = parentElement.tagName
      return parentTag
      console.log("getParentElement returns " + parentTag)
    }
  }

  function setEditorButton(tagName) {
    let tag = tagName
    let allButtons = '#publish-mobile-editor button'
    let activeButton = 'button.tag' + tag

    $(allButtons).removeClass('active');
    $(activeButton).addClass('active');
  };

  function setStandardTag(tagName){
    // if window selection and at least one range is selected
    if (window.getSelection().rangeCount){
      let selectedTag = window.getSelection().anchorNode.parentNode.tagName;
      let formatTag = "<" + tagName + ">"
      console.log("selectedTag set to " + selectedTag)
      if (selectedTag != tagName) {
        console.log("adding " + tagName)
        document.execCommand('formatBlock', false, tagName);
      } else {
        console.log("removing " + tagName)
        document.execCommand('formatBlock', false, 'p');
      }
    }
  };

  // a singular tag cannot be wrapped in any other tags
  function setSingularTag(tagName){

  }

  /// Actions: Media
  $('#editorActionLink').on('click', function(e){
    let link = prompt ("Insert a link", "")
    if (link) {
      document.execCommand("createLink", false, link);
    };
  });
  $('#editorActionUnlink').on('click', function(e){
    document.execCommand("unlink");
  });
});

function toggleFullScreen(elem) {
  if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
    if (elem.requestFullScreen) {
      elem.requestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
